import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import isEmail from "validator/lib/isEmail";
import { Link } from "gatsby";
import { toast } from "react-toastify";
import "./form.scss";
import ReCapthchaV2 from "react-google-recaptcha";

const devValue = (value, falseValue = "") => {
  return process.env.NODE_ENV === "development" ? value : falseValue;
};

const Form = () => {
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState(devValue("Mahesh"));
  const [company, setCompany] = useState(devValue("Test Company"));
  const [email, setEmail] = useState(devValue(`mahesh@atdigital.io`));
  const [password, setPassword] = useState(devValue("password"));
  const [confirmPassword, setConfirmPassword] = useState(devValue("password"));
  const [phone, setPhone] = useState(devValue("0817263546"));
  const [address1, setAddress1] = useState(devValue("Address line 1"));
  const [address2, setAddress2] = useState(devValue(""));
  const [city, setCity] = useState(devValue("City"));
  const [terms, setTerms] = useState(devValue(true, false));
  const [progress, setProgress] = useState(false);
  const [reCap, setReCap] = useState(false);
  const [connectionPassword, setConnectionPassword] = useState("");

  const disabled =
    !name ||
    !isEmail(email) ||
    !password ||
    password.length < 5 ||
    password !== confirmPassword ||
    !phone ||
    !address1 ||
    !city ||
    !terms ||
    !reCap ||
    !connectionPassword ||
    progress;

  const handleRegister = (e) => {
    e.preventDefault();

    setProgress(true);

    const data = {
      name,
      email,
      password,
      phone,
      company,
      address1,
      address2,
      address3: city,
      terms,
      connectionPassword,
      connectionUsername: email.split("@")[0],
    };

    try {
      api("post", "register", data).then((res) => {
        setProgress(false);
        if (res.data.status === "error") {
          toast.error(res.data.message);
        } else if (
          typeof window !== "undefined" &&
          res.data.status === "success"
        ) {
          setSuccess(true);
        }
      });
    } catch (error) {
      setProgress(false);
      setSuccess(false);
      if (!success) {
        toast.error(
          "Error occurred. If this error persists, please contact system administrator."
        );
      }
      console.log(error);
    }
  };

  useEffect(() => {
    if (success) {
      toast.success(
        "Registration successful. Please check your inbox and click on the verification link to proceed."
      );
    }
  }, [success]);

  const onChange = (e) => {
    setReCap(true);
  };

  return (
    <form action="" method="post" onSubmit={handleRegister}>
      <div className="mb-4">
        <label htmlFor="name">Name *</label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Name"
          className="form-control"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="company">Company</label>
        <input
          type="text"
          name="company"
          id="company"
          placeholder="Company"
          className="form-control"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="email">Email *</label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          className="form-control"
          required
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="password">Password *</label>
        <input
          type="password"
          name="Password"
          id="Password"
          placeholder="Password (Please Use 6-10 Characters)"
          className="form-control mb-2"
          minLength="6"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          name="PasswordConfirmation"
          id="PasswordConfirmation"
          placeholder="Confirm Password"
          className="form-control"
          required
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="password">Connection Password *</label>
        <input
          type="text"
          name="connection_password"
          id="connection_password"
          placeholder="This is used to connect to our services"
          className="form-control mb-2"
          minLength="6"
          required
          value={connectionPassword}
          onChange={(e) => setConnectionPassword(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="phone">Phone Number *</label>
        <input
          type="phone"
          name="phone"
          id="phone"
          placeholder="Phone Number"
          className="form-control"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="address">Address *</label>
        <input
          type="text"
          name="adress 1"
          id="address"
          placeholder="Addresss Line 1*"
          className="form-control mb-2"
          required
          value={address1}
          onChange={(e) => setAddress1(e.target.value)}
        />
        <input
          type="text"
          name="address2"
          id="address"
          placeholder="Address Line 2(Optional)"
          className="form-control mb-2"
          value={address2}
          onChange={(e) => setAddress2(e.target.value)}
        />
        <input
          type="text"
          name="city"
          id="address"
          placeholder="City"
          className="form-control mb-2"
          required
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
      </div>

      <div className="form-check mb-4">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          onChange={() => setTerms(!terms)}
          checked={terms}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          I agree to the company's{" "}
          <Link to={"/policies/t&c/"}>terms and conditions</Link>
        </label>
      </div>

      <ReCapthchaV2
        className="mb-4 rounded"
        sitekey="6Le7NogfAAAAAAEpO5of33E3sZ9sl3R8dgHMK9j8"
        onChange={(e) => onChange(e)}
      />

      <input
        type="submit"
        value="Register"
        disabled={disabled}
        className="btn btn-primary rounded-pill mb-4 ms-auto me-auto text-center d-block w-100"
      />
    </form>
  );
};

export default Form;
