import * as React from "react";
import Layout from "../../../layout";
import Form from "./_Form";
import {Link} from "gatsby";
import "../user.css";

// markup
const RegisterPage = () => {
  return (
    <Layout
      title={"Get New Connection (Register)"}
      description={
        "You need to first Register with us to request for a new connection. Our Continuously Operating Reference Station (CORS) network deliver incredibly accurate and reliable cent metric measurement with more reference stations around the country."
      }
      publicPage
    >
      <section className="register py-8 py-lg-16">
        <article className="container row px-8 mx-auto">
          <div className="col-xs-12 col-lg-6">
            <h4>Why Register with CORSNET?</h4>

            <ul className="px-0">
              {whyList.map((item, idx) => (
                <li key={idx}>
                  <span data-uk-icon="check">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline
                        fill="none"
                        stroke="#000"
                        strokeWidth="1.1"
                        points="4,10 8,15 17,4"
                      />
                    </svg>
                  </span>{" "}
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-xs-12 col-lg-6">
            <Form />
            Already have an account? <Link to={"/user/login/"}>Login</Link>
          </div>
        </article>
      </section>
    </Layout>
  );
};

export default RegisterPage;

const whyList = [
  "By registering an account, you will be able to request service from CORSNET.",
  "After successful registration, you will receive a confirmation email to the email address provided.",
  "You are required to click the verification link to complete the registration.",
  "Your account will not be active until the confirmation process is done. Also you are not able to submit service requests until account is activated",
  "You are allowed to create only one account with your email.",
  "Please note that all the fields marked with * are required.",
  "Your password should consists with 5 or more characters.",
];
